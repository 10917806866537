import clsx from 'clsx'
import { graphql, navigate } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import * as React from 'react'
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share'
import Layout from '../../components/layout'
import Seo from '../../components/seo'

const BlogPost = ({ data, location }) => {
  const url = location.href ? location.href : ''
  const { title, author, date, prevPost, nextPost, email } =
    data.mdx.frontmatter

  const wpm = 225
  const words = data.mdx.body.trim().split(/\s+/).length
  const readingTime = Math.ceil(words / wpm)

  return (
    <Layout>
      <Seo title={title}></Seo>
      <div className="relative py-16 overflow-hidden bg-white">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div
            className="relative h-full mx-auto text-lg max-w-prose"
            aria-hidden="true"
          >
            <svg
              className="absolute transform translate-x-32 top-12 left-full"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-blue-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
              />
            </svg>
            <svg
              className="absolute transform -translate-x-32 -translate-y-1/2 top-1/2 right-full"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-blue-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
            <svg
              className="absolute transform translate-x-32 bottom-12 left-full"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-blue-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
              />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="mx-auto text-lg max-w-prose">
            <h1>
              <span className="block mt-2 text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl md:text-5xl font-display">
                {title}
              </span>
            </h1>
            <div className="flex items-center justify-center my-12">
              <div>
                <a
                  href={`mailto:${email}`}
                  className="block font-semibold text-center text-gray-900 no-underline md:text-lg"
                >
                  {author}
                </a>
                <div className="flex mt-2 space-x-1 text-sm text-gray-500">
                  <time dateTime={date}>{date}</time>
                  <span aria-hidden="true">&middot;</span>
                  <span>{readingTime} min read</span>
                </div>
              </div>
            </div>
          </div>
          <div className="mx-auto mt-6 prose prose-lg text-gray-500 prose-blue">
            <MDXRenderer>{data.mdx.body}</MDXRenderer>
            <div className="flex items-center justify-between mt-8">
              <button
                type="button"
                disabled={prevPost === null}
                onClick={() => prevPost && navigate(`/blog/${prevPost}`)}
                className={clsx(
                  `inline-flex items-center px-4 py-2 border border-slate-300 shadow-sm text-sm font-medium rounded-md text-slate-700 bg-white hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                    prevPost === null && `cursor-not-allowed opacity-50`
                  }`,
                )}
              >
                &larr; Previous Post
              </button>
              <button
                type="button"
                disabled={nextPost === null}
                onClick={() => nextPost && navigate(`/blog/${nextPost}`)}
                className={clsx(
                  `inline-flex items-center px-4 py-2 border border-slate-300 shadow-sm text-sm font-medium rounded-md text-slate-700 bg-white hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                    nextPost === null && `cursor-not-allowed opacity-50`
                  }`,
                )}
              >
                Next Post &rarr;
              </button>
            </div>
          </div>

          <div className="flex items-center justify-center mt-16 space-x-4">
            <LinkedinShareButton url={url}>
              <LinkedinIcon size={48} round />
            </LinkedinShareButton>
            <FacebookShareButton url={url}>
              <FacebookIcon size={48} round />
            </FacebookShareButton>
            <TwitterShareButton url={url}>
              <TwitterIcon size={48} round />
            </TwitterShareButton>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        author
        slug
        email
        prevPost
        nextPost
        date(formatString: "MMMM D, YYYY")
      }
      body
    }
  }
`

export default BlogPost
